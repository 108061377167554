import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import { keywords } from "../Constants/ProjectData";
const resumeUrl = process.env.REACT_APP_RESUME_URL;

window.addEventListener("scroll", () => {
  const header = document.getElementById("header");
  if (window.scrollY > 0) {
    header.classList.add("bg-secondery"); // Changed to your secondery color
    header.classList.remove("bg-primary");
  } else {
    header.classList.remove("bg-secondery");
    header.classList.add("bg-primary");
  }
});

const Header = () => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => setToggle(!toggle);

  return (
    <header
      className="flex justify-between px-5 py-2 bg-primary text-white height-10 fixed w-full z-10 transition-all duration-300"
      id="header"
    >
      <a href="/" className="logo text-2xl font-bold text-accent">
        {keywords.companyName}
      </a>

      {/* Desktop Nav */}
      <nav className="hidden md:block">
        <ul className="flex">
          <li>
            <a href="/#about" className="font-bold">
              {keywords.about}
            </a>
          </li>
          <li>
            <a href="/#team" className="font-bold">
              {keywords.ourTeam}
            </a>
          </li>
          {/* <li>
            <a href="/#projects" className="font-bold">{keywords.projects}</a>
          </li> */}
          <li>
            <a href="/#technologies" className="font-bold">
              {keywords.ourSkillsAndTechnologies.split(" ").slice(1, 2)}
            </a>
          </li>
          <li>
            <a href="/#clients" className="font-bold">
              {keywords.clients}
            </a>
          </li>
          {/* <li>
            <Link to="/blog" className="font-bold">{keywords.blog}</Link>
          </li> */}
          <li>
            <a href="/#contact" className="font-bold">
              {keywords.contactUs}
            </a>
          </li>
          {/* <li>
            <a href={resumeUrl} target="_blank" without rel="noreferrer">
              {keywords.resume}
            </a>
          </li> */}
        </ul>
      </nav>

      {/* Mobile Nav */}
      <nav
        className={!toggle ? "mobile-nav left-[-100%]" : "mobile-nav left-0"}
      >
        <ul className="flex flex-col">
          <li>
            <a href="/#about" className="font-bold" onClick={handleToggle}>
              {keywords.about}
            </a>
          </li>
          <li>
            <a href="/#team" className="font-bold" onClick={handleToggle}>
              {keywords.ourTeam}
            </a>
          </li>
          <li>
            <a href="/#technologies" className="font-bold" onClick={handleToggle}>
              {keywords.ourSkillsAndTechnologies.split(" ").slice(1, 2)}
            </a>
          </li>
          <li>
            <a href="/#clients" className="font-bold" onClick={handleToggle}>
              {keywords.clients}
            </a>
          </li>
          {/* <li>
            <a href="/#projects" className="font-bold"  onClick={handleToggle}>{keywords.projects}</a>
          </li>
          <li>
            <a href="/#blog" className="font-bold">{keywords.blog}</a>
          </li> */}
          <li>
            <a href="/#contact" className="font-bold" onClick={handleToggle}>
              {keywords.contactUs}
            </a>
          </li>
          {/* <li>
            <a href={resumeUrl} target="_blank" without rel="noreferrer">
              {keywords.resume}
            </a>
          </li> */}
        </ul>
      </nav>

      {/* Toggle button */}
      <button onClick={handleToggle} className="block md:hidden">
        {!toggle ? <AiOutlineMenu size={30} /> : <AiOutlineClose size={30} />}
      </button>
    </header>
  );
};

export default Header;
