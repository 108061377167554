import { clientsData, keywords } from "../Constants/ProjectData";
import React, { useState, useEffect } from "react";
import "../App.css"; // Import custom CSS for styling
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Import icons


export default function Clients() {
  const [currentIndex, setCurrentIndex] = useState(1); // Start with the second item as the center card
  const [animationState, setAnimationState] = useState(""); // Track the animation state

  const handleNext = () => {
    setAnimationState("animate-prev");
  };

  const handlePrevious = () => {
    setAnimationState("animate-next");
  };

  // Get the three clients to display: center, left, and right
  const getVisibleClients = () => {
    const prevIndex =
      currentIndex === 0 ? clientsData.length - 1 : currentIndex - 1;
    const nextIndex =
      currentIndex === clientsData.length - 1 ? 0 : currentIndex + 1;

    return [
      clientsData[prevIndex],
      clientsData[currentIndex],
      clientsData[nextIndex],
    ];
  };

  const visibleClients = getVisibleClients();

  useEffect(() => {
    if (animationState) {
      const timer = setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          animationState === "animate-next"
            ? prevIndex === clientsData.length - 1
              ? 0
              : prevIndex + 1
            : prevIndex === 0
            ? clientsData.length - 1
            : prevIndex - 1
        );
        setAnimationState("");
      }, 1000); // Duration should match the animation duration

      return () => clearTimeout(timer);
    }
  }, [animationState]);

  return (
    <section className="py-20 bg-primary relative" id="clients">
      <div className="container mx-auto px-6 md:px-12 lg:px-15">
        <div className="mb-12 space-y-4 text-center">
          <h2 className="text-4xl font-bold text-white md:text-5xl">{keywords.ourClients}</h2>
          <p className="text-white lg:w-8/12 lg:mx-auto">
            {keywords.ourClientMessage}
          </p>
        </div>

        {/* Left Scroll Button */}
        <button
          onClick={handlePrevious}
          className={`absolute left-0 top-1/2 transform -translate-y-1/2 text-white bg-primary rounded-full p-3 focus:outline-none z-10 ${
            animationState === "animate-prev" ? "animate-icon-grow-left" : ""
          }`}
        >
          <FaChevronLeft size={24} />
        </button>

        <div className="flex justify-center items-center space-x-8 py-12 relative">
          {visibleClients.map((client, index) => (
            <div
              key={client.id}
              className={`transition-transform duration-1000 ease-in-out transform flex-none w-60 ${
                index === 1
                  ? `scale-110 z-20 opacity-100 ${animationState}` // Center card
                  : `scale-90 opacity-50 ${animationState}` // Left and right cards
              } bg-secondery rounded-lg shadow-lg p-6 text-center text-white`}
            >
              <div className="w-24 h-24 mx-auto rounded-full overflow-hidden mb-2">
                <img
                  src={client.image}
                  alt={client.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <h3 className="text-xl font-semibold">{client.name}</h3>
              <p className="italic">"{client.comment}"</p>
              <div className="text-yellow-500 mt-4">
                {'⭐'.repeat(client.rating)} {/* Display stars based on the rating */}
              </div>
            </div>
          ))}
        </div>

        {/* Right Scroll Button */}
        <button
          onClick={handleNext}
          className={`absolute right-0 top-1/2 transform -translate-y-1/2 text-white bg-primary rounded-full p-3 focus:outline-none z-10 ${
            animationState === "animate-next" ? "animate-icon-grow-right" : ""
          }`}
        >
          <FaChevronRight size={24} />
        </button>
      </div>
    </section>
  );
}
