import React from "react";
import { keywords } from "../Constants/ProjectData";

const teams = [
  {
    id: 0,
    name: "Inayat Ali",
    role: "CEO",
    image: "./inayat.jpg",
  },
  // {
  //   id: 1,
  //   name: "Walayat Ali",
  //   role: "Senior Frond-end developer",
  //   image: "./walayat.jpeg",
  // },
  // {
  //   id: 2,
  //   name: "Wilayat Daniyal",
  //   role: "Senior Backend developer",
  //   image: null,
  // },
  // {
  //   id: 3,
  //   name: "Ambareen Fatima",
  //   role: "Human Resource Manager (HR)",
  //   image: "./female.png",
  // },
  {
    id: 4,
    name: "Sardar Hussain",
    role: "React Native (Intern)",
    image: null, //require("./sardarHussain.jpg"),
  },
  {
    id: 5,
    name: "Muhammad Hadi",
    role: "Graphic Designer (intern)",
    image: null,
  },
];

export default function OurTeam() {
  return (
    <section className="section py-20 bg-white bg-primary" id="team">
      <div className="container mx-auto px-6 md:px-12 lg:px-8">
        <div className="mb-12  space-y-4 text-center">
          <h2 className="text-4xl font-bold text-white md:text-5xl">
            {keywords.ourTeam}
          </h2>
          <p className="text-white lg:w-8/12 lg:mx-auto">
            {keywords.quoteMessage}
          </p>
        </div>
        <div className="py-20 grid gap-28 md:gap-12 md:grid-cols-3">
          {teams.map((team) => (
            <div
              className="border-t-4 space-y-4  group text-center mb-12"
              key={team.id}
            >
              <div className="w-32 h-32 -mt-16  mx-auto rounded-[2rem]  rotate-45 overflow-hidden">
                <img
                  src={team?.image || "./image.jpg"}
                  alt=" team member"
                  loading="lazy"
                  width="640"
                  height="805"
                  className="w-full h-full object-cover -rotate-45 scale-125 mx-auto  transition duration-1000 group-hover:sclae-[1.7]"
                />
              </div>
              <div className="text-center ">
                <h5 className="text-xl text-white font-semibold">
                  {team.name}
                </h5>
                <span className=" text-sm text-white "> {team.role}</span>
              </div>
              {/* <a
                href="#"
                className="block w-max mx-auto text-center text-blue-500"
              >
                {keywords.viewBio}
              </a> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
