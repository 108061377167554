import React from "react";
import HeroImg from "../assets/hero-img.png";

import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineTwitter,
  AiOutlineYoutube,
} from "react-icons/ai";
import { keywords } from "../Constants/ProjectData";

const Hero = () => {
  return (
    <section className="bg-primary px-5 text-white py-32">
      <div className="container mx-auto grid md:grid-cols-2 items-center justify-center md:justify-between">
        <div className="hero-info pb-5 md:pb-0">
          <h1 className="text-4xl lg:text-6xl">
            <br />
            {keywords.welcomeTo}
            <br />
            <span className="text-accent">
              {keywords.companyName}
            </span>{" "}
            <br />
            {/* <span className="text-accent">
              {keywords.companyName.split(" ")[1]}
            </span>{" "}
            <br /> */}
          </h1>

          <p className="py-5">{keywords.description}</p>

          <div className="flex py-5 ">
            <a
              href="https://x.com/InnuNextTech"
              className="pr-4 inline-block text-accent hover:text-white"
              target="_blank"
            >
              {" "}
              <AiOutlineTwitter size={40} />{" "}
            </a>
            <a
              href="https://www.instagram.com/innunext/"
              className="pr-4 inline-block text-accent hover:text-white"
              target="_blank"
            >
              {" "}
              <AiOutlineInstagram size={40} />{" "}
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61565176560771"
              className="pr-4 inline-block text-accent hover:text-white"
              target="_blank"
            >
              {" "}
              <AiOutlineFacebook size={40} />{" "}
            </a>
            <a
              href="https://www.linkedin.com/company/104642441/admin/dashboard/"
              className="pr-4 inline-block text-accent hover:text-white"
              target="_blank"
            >
              {" "}
              <AiOutlineLinkedin size={40} />{" "}
            </a>
          </div>

          <a
            href="/#projects"
            className=" btn bg-accent border-2 border-[#7477FF] text-white px-12 py-3 hover:bg-transparent rounded-md"
          >
            {keywords.seeProjects}
          </a>
        </div>

        <div className="hero-img">
          <img
            src={HeroImg}
            alt="coding illustration"
            className="lgw-[80%] ml-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
