import css from "./css.png";
import firebase from "./firebase.png";
import html from "./html.png";
import react from "./react.png";
import javascript from "./javascript.png";
import java from './java.png'
import mui from "./mui.png";
import nodejs from "./nodejs.png";
import typescript from "./typescript.png";
import flutter from "./flutter.png";
import aws from "./aws.png";
import android from "./android.png";
import cicd from "./cicd.png";
import docker from "./docker.png";
import swift from "./swift.png";
import tailwind from "./tailwind.png";
import Kubernetes from "./Kubernetes.png";
import mongodb from "./mongodb.svg";
import supabase from "./supabase.png";
import postgres from "./postgre.png";
import xcode from './xcode.png'

export const technologiesIcons = {
  images: [css, firebase, html, react, javascript, mui, nodejs],
};

export const Frontend = {
  images: [
    { logo: react, title: "React-Native" },
    { logo: javascript, title: "JavaScript" },
    { logo: typescript, title: "TypeScript" },
    { logo: react, title: "React-JS" },
    { logo: tailwind, title: "TailwindCSS" },
  ],
};

export const Backend = {
  images: [
    { logo: nodejs, title: "NodeJS" },
    { logo: firebase, title: "Firebase" },
    { logo: supabase, title: "Supabase" },
    { logo: mongodb, title: "MongoDB (Atlas)" },
    { logo: postgres, title: "PostgreSQL" },
  ],
};

export const Applications = {
  images: [
    { logo: react, title: "React-Native" },
    { logo: swift, title: "SWIFT" },
    { logo: android, title: "Android Studio" },
    { logo: java, title: "JAVA" },
    { logo: xcode, title: "XCode" },
  ],
};

// export const DevOps = {
//   images: [
//     { logo: aws, title: "AWS" },
//     { logo: docker, title: "Docker" },
//     { logo: cicd, title: "CI/CD" },
//     { logo: Kubernetes, title: "Kubernetes" },
//   ],
// };
